import { type FC } from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useFetch from '../hooks/fetch'

const PishingCapture: FC<WithoutProps> = () => {
  const { contextOwner = '', ref = '' } = useParams()

  const requestBody = {
    contextOwner,
    ref,
  }

  const { data: response, isLoading } = useFetch<
    {
      success: boolean
    },
    typeof requestBody
  >(
    {
      url: process.env.REACT_APP_CATCHER_API_URL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: String(process.env.REACT_APP_CATCHER_API_TOKEN),
        Accept: 'application/json',
      },
      requestBody,
    },
    [contextOwner, ref]
  )

  return isLoading ? (
    <Spinner animation="border" />
  ) : !!response?.success ? (
    <span>success</span>
  ) : (
    <span>error</span>
  )
}

export default PishingCapture
