import './styles/init.sass'
import { type FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RoutesMap from './routes/Map'

const App: FC<WithoutProps> = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        {RoutesMap.map((route, key) => (
          <Route key={key} {...route} />
        ))}
      </Routes>
    </BrowserRouter>
  )
}

export default App
