import { useAsyncMemo } from './async-memo'
import { type DependencyList } from 'react'
import axios from 'axios'

type FetcherOptions<T> = {
  url: string
  headers?: Record<string, string>
} & Exclusive<
  {
    method?: 'POST' | 'PUT' | 'PATCH'
    requestBody: T
  },
  {
    method?: 'GET' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'TRACE'
    requestBody?: never
  }
>

export const useFetch = <ResponseBodyType = unknown, RequestBodyType = any>(
  { url, method, headers, requestBody }: FetcherOptions<RequestBodyType>,
  deps: DependencyList
) => {
  const asyncMemoizedData = useAsyncMemo(
    () =>
      new Promise<ResponseBodyType | null>((resolve) => {
        axios
          .request<ResponseBodyType>({
            method,
            url,
            headers,
            data: requestBody,
            withCredentials: false,
          })
          .then((response) => resolve(response.data))
          .catch(() => resolve(null))
      }),
    deps
  )

  return asyncMemoizedData
}

export default useFetch
