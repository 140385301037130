import { type RouteProps } from 'react-router-dom'
import PishingCapture from '../pages/PishingCapture'
import Welcome from '../pages/Welcome'

const RoutesMap: Array<RouteProps> = [
  {
    path: '/',
    element: <Welcome />,
  },
  {
    path: '/register/:contextOwner/:ref',
    element: <PishingCapture />,
  },
]

export default RoutesMap
