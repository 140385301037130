import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Welcome: FC<WithoutProps> = () => {
  return (
    <Container className="d-flex flex-column min-vh-100">
      <Row className="flex-grow-1 align-content-center">
        <Col className="d-flex flex-column text-center gap-4">
          <span className="text-success">
            <FontAwesomeIcon icon={faLock} size="3x" />
          </span>
          <span className="fs-1">Secure Catcher</span>
          <span className="fs-4 text-muted opacity-25">
            This application is under development
          </span>
        </Col>
      </Row>
    </Container>
  )
}

export default Welcome
